import { render, staticRenderFns } from "./GetIMGByTag.vue?vue&type=template&id=d1ec74aa&scoped=true&"
import script from "./GetIMGByTag.vue?vue&type=script&lang=js&"
export * from "./GetIMGByTag.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1ec74aa",
  null
  
)

export default component.exports